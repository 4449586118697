// src/components/styles.js

export const colors = {
    primary: '#0A84FF', // Azul primário
    secondary: '#147EFB',
    background: '#E5F1FB',
    header: '#F7F9FB',
    chatBackground: '#CCE4FF',
    senderBubble: '#CCE4FF',
    receiverBubble: '#FFFFFF',
    textPrimary: '#0A0A0A',
    textSecondary: '#4A4A4A',
  };
  