// src/components/ContactList.js
import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { colors } from './styles';
import { FaSearch, FaComments } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';

const Container = styled.div`
  width: 30%;
  display: ${(props) => (props.isVisible ? 'flex' : 'none')};
  flex-direction: column;
  border-right: 1px solid #ddd;
  height: 100vh;
  overflow-y: auto;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Header = styled.div`
  background-color: ${colors.header};
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Logo = styled.img`
  max-width: 200px;
  height: auto;
  @media (max-width: 768px) {
    max-width: 150px;
  }
`;

const SearchBox = styled.div`
  background-color: #f6f6f6;
  padding: 5px 10px;
  display: flex;
  align-items: center;
`;

const SearchInput = styled.input`
  flex: 1;
  border: none;
  background: none;
  margin-left: 10px;
  font-size: 14px;
  outline: none;
`;

const ContactItem = styled.div`
  display: flex;
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: #ebebeb;
  }
`;

const Avatar = styled.div`
  background-color: ${colors.primary};
  color: #fff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  margin-right: 10px;
`;

const ContactInfo = styled.div`
  flex: 1;
  border-bottom: 1px solid #f0f0f0;
`;

const ContactName = styled.div`
  font-weight: bold;
  color: ${colors.textPrimary};
`;

const LastMessage = styled.div`
  font-size: 12px;
  color: ${colors.textSecondary};
`;

function ContactList({ isVisible, onSelectContact, contacts, setContacts }) {
  const [skip, setSkip] = useState(0);
  const [loading, setLoading] = useState(false);
  const apiKey = 'Key bXlkZWNoYXRkZXY6M2xacjgxY2xMTnFrWkZqWFJlUEU=';

  const getThirtyDaysAgo = () => {
    const date = new Date();
    date.setDate(date.getDate() - 30);
    return date.toISOString();
  };

  const fetchContacts = useCallback(async () => {
    setLoading(true);
    const lastThirtyDays = getThirtyDaysAgo();
    const encodedFilter = encodeURIComponent(
      `(source ne 'blip.ai' or source eq null) and (lastmessagedate ge datetimeoffset'${lastThirtyDays}')`
    );

    const response = await fetch('https://myde.http.msging.net/commands', {
      method: 'POST',
      headers: {
        Authorization: apiKey,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: uuidv4(),
        method: 'get',
        uri: `/contacts?$skip=${skip}&$take=20&$filter=${encodedFilter}`,
        to: 'postmaster@msging.net',
        from: 'mydechatdev@msging.net',
      }),
    });

    const data = await response.json();
    if (data.resource && data.resource.items) {
      const fetchedContacts = data.resource.items.map((item) => ({
        name: item.name || item.identity,
        lastMessage: item.extras?.lastMessage || '',
        identity: item.identity,
        lastMessageDate: item.lastMessageDate,
      }));

      setContacts((prevContacts) => {
        const allContacts = [...prevContacts, ...fetchedContacts];

        const contactMap = new Map();

        allContacts.forEach((contact) => {
          const existing = contactMap.get(contact.identity);
          if (
            !existing ||
            new Date(contact.lastMessageDate) > new Date(existing.lastMessageDate)
          ) {
            contactMap.set(contact.identity, contact);
          }
        });

        return Array.from(contactMap.values());
      });
    }
    setLoading(false);
  }, [apiKey, skip, setContacts]);

  useEffect(() => {
    fetchContacts();
  }, [fetchContacts]);

  const handleScroll = (e) => {
    if (
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight &&
      !loading
    ) {
      setSkip((prevSkip) => prevSkip + 20);
    }
  };

  return (
    <Container isVisible={isVisible} onScroll={handleScroll}>
      <Header>
        <Logo src="https://pub-0f3a992e0a0b42c7a9e226a175513bee.r2.dev/Group%203445.png" alt="Logo" />
        <FaComments size={20} color={colors.textSecondary} />
      </Header>
      <SearchBox>
        <FaSearch color={colors.textSecondary} />
        <SearchInput placeholder="Pesquisar ou começar uma nova conversa" />
      </SearchBox>
      {contacts.map((contact, index) => (
        <ContactItem key={index} onClick={() => onSelectContact(contact)}>
          <Avatar>{contact.name.charAt(0)}</Avatar>
          <ContactInfo>
            <ContactName>{contact.name}</ContactName>
            <LastMessage>{contact.lastMessage}</LastMessage>
          </ContactInfo>
        </ContactItem>
      ))}
      {loading && <div>Carregando...</div>}
    </Container>
  );
}

export default ContactList;
