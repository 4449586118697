import React from 'react';
import styled from 'styled-components';
import { colors } from './styles';

const MessageRow = styled.div`
  display: flex;
  justify-content: ${(props) =>
    props.from === 'atendente' ? 'flex-end' : 'flex-start'};
  margin-bottom: 10px;
`;

const MessageBubble = styled.div`
  background-color: ${(props) =>
    props.from === 'atendente' ? colors.senderBubble : colors.receiverBubble};
  color: ${colors.textPrimary};
  padding: 8px 12px;
  border-radius: 7.5px;
  max-width: 60%;
  font-size: 14px;
  word-wrap: break-word;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

function Message({ from, text }) {
  return (
    <MessageRow from={from}>
      <MessageBubble from={from} dangerouslySetInnerHTML={{ __html: text }} />
    </MessageRow>
  );
}

export default Message;
