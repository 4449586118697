// src/firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyD0KIJg_08DVxUpvIy2kWrOQ01KL4TKCCw",
    authDomain: "mydechat.firebaseapp.com",
    projectId: "mydechat",
    storageBucket: "mydechat.appspot.com",
    messagingSenderId: "741724052157",
    appId: "1:741724052157:web:f543cdc29e3042669db5d4"
};

// Inicializa o Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };
